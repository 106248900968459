import Icon from "@kamae-apps/shared/Components/Fondations/Icons/Icon"
import useClickOutside from "@kamae-apps/shared/Hooks/useClickOutside"
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt"
import type TUser from "@kamae-apps/shared/Types/TUser"
import { isFirstArrayItem, isLastArrayItem } from "@kamae-apps/shared/utils"
import clsx from "clsx"
import type { TFunction } from "i18next"
import { FC, useRef, useState } from "react"
import config from "../../../variable"

type UserMenuProps = {
  t: TFunction
  user: TUser
  entries: UserMenuEntries[]
}

export type UserMenuEntries = {
  displayNameKey: string
  iconName: string
  onClick: () => void
}

export const UserMenu: FC<UserMenuProps> = (props: UserMenuProps) => {
  const userMenuRef = useRef<HTMLDivElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  useClickOutside(userMenuRef, () => setIsMenuOpen(false))

  const [avatarError, setAvatarError] = useState(false)
  const avatarSource = `${config.api}/avatar/${props.user?.avatar}/${getBeltColor(props.user.level)}`

  return (
    <div
      ref={userMenuRef}
      className="relative"
    >
      <button
        className={clsx(
          "h-13 flex w-full items-center gap-3 rounded-xl bg-white py-3 px-5",
          isMenuOpen ? "bg-opacity-100" : "bg-opacity-30 hover:bg-opacity-50"
        )}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        type="button"
      >
        <div className={"h-7 w-7 flex rounded-full justify-center	items-center"}>
          {avatarError || !avatarSource ? (
            <Icon
              name="man"
              size="m"
            />
          ) : (
            <img
              src={avatarSource}
              alt="Avatar"
              onError={() => setAvatarError(true)}
            />
          )}
        </div>
        <p className="caption-regular">{props.user.first_name}</p>
      </button>
      {isMenuOpen && (
        // TODO: to replace with the dropdown menu component
        <div className="absolute bottom-0 left-[205px] min-w-[240px]">
          {props.entries.map((entry, index) => {
            return (
              <button
                key={props.t(entry.displayNameKey)}
                className={clsx(
                  "shadow-xs flex h-11 w-full items-center gap-3 bg-white py-3 px-5 text-neutral-gray-900",
                  isFirstArrayItem(index) && "rounded-t-xl",
                  isLastArrayItem(index, props.entries.length) && "rounded-b-xl border-t"
                )}
                onClick={() => {
                  setIsMenuOpen(false)
                  entry.onClick()
                }}
                type="button"
              >
                <div className="text-neutral-gray-500">
                  <Icon name={entry.iconName} />
                </div>
                <p className="body-sm-regular whitespace-nowrap">{props.t(entry.displayNameKey)}</p>
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}
