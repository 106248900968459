import { useAuth0 } from "@auth0/auth0-react"
import { useCryptr } from "@cryptr/cryptr-react"
import { MenuEntryList } from "@kamae-apps/shared/Components/Molecules/Menu/MenuEntryList"
import { UserMenu, type UserMenuEntries } from "@kamae-apps/shared/Components/Molecules/UserMenu/UserMenu"
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus"
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope"
import type { TFunction } from "i18next"
import posthog from "posthog-js"
import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import { TutorialModal } from "../../old/Components/App/TutorialModal"
import { ToggleButton } from "../../old/Components/NewPhishing/AutomationPage/Components/ToggleButton"
import { useFeatureFlags } from "../../old/hooks/posthog/useFeatureFlags"
import useUser from "../../old/hooks/useUser"
import config from "../../variable"

interface MenuProps {
  t: TFunction
  loaded: boolean
  //TODO: might need to give user informations as props instead of calling hook here
}

export const Menu: FC<MenuProps> = ({ t, loaded }: MenuProps) => {
  const { user } = useUser()
  const isAdmin = scopeHas(user.scope, Scope.COMPANY_ADMIN)
  const featureFlag = useFeatureFlags()
  const [displayTutorial, setDisplayTutorial] = useState(false)

  const isTeamLeader = user.id === user.team.leader?.id && featureFlag.includes("team-leaders")

  const canToggleRebranding = featureFlag.includes("toggle-rebranding")
  const [rebrandingDisplay, setRebrandingDisplay] = useState(localStorage.getItem("rebrandingDisplay") === "true")

  const auth0 = useAuth0()
  const cryptr = useCryptr()
  const navigate = useNavigate()

  const userMenuEntries: UserMenuEntries[] = [
    {
      displayNameKey: "app:userMenu.avatar",
      iconName: "pen",
      onClick: () => navigate("/profile"),
    },
    {
      displayNameKey: "app:userMenu.tutorial",
      iconName: "refreshBack",
      onClick: () => setDisplayTutorial(true),
    },
    {
      displayNameKey: "app:userMenu.logout",
      iconName: "logOut",
      onClick: () => {
        posthog.reset()
        if (cryptr.isAuthenticated()) {
          cryptr.logOut(undefined, `${config.app}/login`, false)
        } else if (auth0.isAuthenticated) {
          auth0.logout({ returnTo: `${window.location.origin}/login` })
        }
        localStorage.clear()
        setRebrandingDisplay(false)
      },
    },
  ]

  return (
    <>
      <div className="flex h-screen w-[240px] flex-col items-center gap-4 p-5">
        <img
          className="h-[34px] w-full"
          src="/kamaeLogo.svg"
          alt="kamae-logo"
        />
        <div className="relative w-[172px] h-[176px]">
          <img
            className="absolute bottom-0 left-0 right-0 h-[140px] w-[172px]"
            src="/avatar-background.png"
            alt="avatar-background"
          />
          <img
            className="absolute bottom-0 left-0 right-0 mx-auto h-[176px] w-[160px]"
            src="/new-kamae-san.png"
            alt="kamae-san"
          />
        </div>
        <MenuEntryList
          t={t}
          loaded={loaded}
          isAdmin={isAdmin}
          isTeamLeader={isTeamLeader}
        />
        {canToggleRebranding && (
          <ToggleButton
            leftTitle={"legacy"}
            leftAction={() => {
              setRebrandingDisplay(false)
              localStorage.setItem("rebrandingDisplay", "false")
              window.location.reload()
            }}
            rightTitle={"rebranding"}
            rightAction={() => {
              setRebrandingDisplay(true)
              localStorage.setItem("rebrandingDisplay", "true")
              window.location.reload()
            }}
            activeSide={rebrandingDisplay === true ? "right" : "left"}
          />
        )}
        <div className="mt-auto w-full">
          <UserMenu
            t={t}
            user={user}
            entries={userMenuEntries}
          />
        </div>
      </div>
      {user.company.status !== CompanyStatus.Discovery && (
        <TutorialModal
          display={displayTutorial}
          setDisplay={setDisplayTutorial}
        />
      )}
    </>
  )
}
