import useApiStatus from "@kamae-apps/shared/Hooks/useApiStatus/useApiStatus"
import { UserConfigStateProvider } from "@kamae-apps/shared/Hooks/userConfig/Context"
import Loading from "@kamae-apps/shared/old/Component/Loading/Loading"
import React, { FC } from "react"
import { Suspense } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { Menu } from "./Components/Organisms/Menu"
import MaintenancePage from "./old/Error/MaintenancePage/MaintenancePage"

const NewLogin = React.lazy(() => import("./Routes/Login/LoginPage"))

type AppProps = {
  loaded: boolean
}

export const RebrandedApp: FC<AppProps> = ({ loaded }) => {
  const location = useLocation()
  const apiStatus = useApiStatus()
  const { t } = useTranslation("app")

  return (
    <div
      data-theme={"rebranding"}
      className="bg-gradient-ocean"
    >
      {((apiStatus.code === 503 || apiStatus.code === -1) && <MaintenancePage />) || (
        <Routes>
          <Route
            element={
              <UserConfigStateProvider loaded={loaded}>
                <Menu
                  t={t}
                  loaded={loaded}
                />
              </UserConfigStateProvider>
            }
          >
            <Route
              path={"/organization"}
              element={<Suspense fallback={<Loading />}>{/* <Organization /> */}</Suspense>}
            />
            <Route
              path={"/supervision"}
              element={<Suspense fallback={<Loading />}>{/* <Supervision /> */}</Suspense>}
            />
            <Route
              path={"/home"}
              element={<Suspense fallback={<Loading />}>{/* <Home /> */}</Suspense>}
            />
            <Route
              path={"/elearning"}
              element={<Suspense fallback={<Loading />}>{/* <Trainings /> */}</Suspense>}
            />
            <Route
              path={"/phishing"}
              element={<Suspense fallback={<Loading />}>{/* <Phishing /> */}</Suspense>}
            />
            <Route
              path={"/reflexes"}
              element={<Suspense fallback={<Loading />}>{/* <Reflexes /> */}</Suspense>}
            />
            <Route
              path={"/profile"}
              element={<Suspense fallback={<Loading />}>{/* <Profile /> */}</Suspense>}
            />
          </Route>
          <Route>
            <Route
              path={"/login"}
              element={
                <Suspense fallback={<Loading />}>
                  <NewLogin />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      )}
    </div>
  )
}
